import "core-js/modules/es6.regexp.to-string";

var crypto = require('crypto');

var forge = require('node-forge');

import website from '@/config/website';
import { getStore } from '@/utils/store';
export var encrypt = function encrypt(datas) {
  var keyStr = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCEFTNL2xu8qfmieLFg0LSFfxIs\n' + 'RNGeYwBfHmE8a8WdD8FL2aQ7fOhkqrZrrHoWlZafPbhk94U3q6CdK2EMdzncx2sO\n' + 'MTrL3pYGnNUW9NYLUclwXLOdaGDC/LH+9OShfFAg9CriTuOuycqs4tfgL8WThMcz\n' + 'GkdvFlSe/fp6eh8yKQIDAQAB';
  var publicKeyStr = "-----BEGIN PUBLIC KEY-----\n".concat(keyStr, "\n") + "-----END PUBLIC KEY-----";
  var encryptStr = crypto.publicEncrypt({
    key: publicKeyStr,
    padding: crypto.constants.RSA_PKCS1_PADDING
  }, Buffer.from(datas, 'utf8'));
  return encryptStr.toString('base64');
};
export var decrypt = function decrypt(data) {
  var util = forge.util;
  var clientRandom = getStore({
    name: 'clientRandom'
  });
  var sha256MD = forge.sha256.create();
  sha256MD.update(clientRandom, 'utf8');
  var aesKey = sha256MD.digest();
  var iv = aesKey.copy().getBytes().slice(0, 16);
  var cipher = forge.cipher.createDecipher('AES-CBC', util.createBuffer(aesKey));
  cipher.start({
    iv: util.createBuffer(iv)
  });
  cipher.update(util.createBuffer(util.decode64(data)));
  cipher.finish();
  var output = cipher.output.getBytes().toString();
  return output;
};
export var aesEncrypt = function aesEncrypt(data) {
  var clientRandom = getStore({
    name: 'clientRandom'
  });
  var deviceId = getStore({
    name: 'deviceid'
  });
  var serverRandom = getStore({
    name: 'serverRandom'
  });
  var util = forge.util;
  var keyStr = "".concat(deviceId, ":").concat(clientRandom, ":").concat(serverRandom);
  var sha256MD = forge.sha256.create();
  sha256MD.update(keyStr, 'utf8');
  var aesKey = sha256MD.digest();
  var iv = aesKey.copy().getBytes().slice(0, 16);
  var cipher = forge.cipher.createCipher('AES-CBC', util.createBuffer(aesKey));
  cipher.start({
    iv: util.createBuffer(iv)
  });
  cipher.update(util.createBuffer(data, 'utf8'));
  cipher.finish();
  var output = cipher.output.getBytes().toString();
  return util.encode64(output);
};
export var aesDecrypt = function aesDecrypt(data) {
  var clientRandom = getStore({
    name: 'clientRandom'
  });
  var deviceId = getStore({
    name: 'deviceid'
  });
  var serverRandom = getStore({
    name: 'serverRandom'
  });
  var util = forge.util;
  var keyStr = "".concat(deviceId, ":").concat(clientRandom, ":").concat(serverRandom);
  var sha256MD = forge.sha256.create();
  sha256MD.update(keyStr, 'utf8');
  var aesKey = sha256MD.digest();
  var iv = aesKey.copy().getBytes().slice(0, 16);
  var cipher = forge.cipher.createDecipher('AES-CBC', util.createBuffer(aesKey));
  cipher.start({
    iv: util.createBuffer(iv)
  });
  cipher.update(util.createBuffer(util.decode64(data)));
  cipher.finish();
  var output = cipher.output.getBytes();
  return util.decodeUtf8(output);
};
export var signature = function signature(data) {
  var util = forge.util;
  var sha256MD = forge.sha256.create();
  sha256MD.update(data, 'utf8');
  var aesKey = sha256MD.digest();
  return util.bytesToHex(aesKey);
};
export var aesEncryptStore = function aesEncryptStore(data) {
  var util = forge.util;
  var keyStr = website.companyInfos + website.groupInfos + website.slogan;
  var sha256MD = forge.sha256.create();
  sha256MD.update(keyStr, 'utf8');
  var aesKey = sha256MD.digest();
  var iv = aesKey.copy().getBytes().slice(0, 16);
  var cipher = forge.cipher.createCipher('AES-CBC', util.createBuffer(aesKey));
  cipher.start({
    iv: util.createBuffer(iv)
  });
  cipher.update(util.createBuffer(data, 'utf8'));
  cipher.finish();
  var output = cipher.output.getBytes().toString();
  return util.encode64(output);
};
export var aesDecryptStore = function aesDecryptStore(data) {
  var util = forge.util;
  var keyStr = website.companyInfos + website.groupInfos + website.slogan;
  var sha256MD = forge.sha256.create();
  sha256MD.update(keyStr, 'utf8');
  var aesKey = sha256MD.digest();
  var iv = aesKey.copy().getBytes().slice(0, 16);
  var cipher = forge.cipher.createDecipher('AES-CBC', util.createBuffer(aesKey));
  cipher.start({
    iv: util.createBuffer(iv)
  });
  cipher.update(util.createBuffer(util.decode64(data)));
  cipher.finish();
  var output = cipher.output.getBytes();
  return util.decodeUtf8(output);
};