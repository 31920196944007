import { getStore, setStore } from "../../utils/store";
var state = {
  currentExam: getStore({
    name: 'currentExam'
  }) || null
};
var mutations = {
  SET_CURRENT_EXAM: function SET_CURRENT_EXAM(state, list) {
    state.currentExam = list;
    setStore({
      name: 'currentExam',
      content: list
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations
};