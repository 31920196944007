/*
 * @Author: your name
 * @Date: 2020-01-10 15:56:14
 * @LastEditTime: 2020-01-13 14:07:06
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \smartGovernmentSaber\src\config\website.js
 */
import regionInfo from '@/config/region.config';
/**
 * 全局配置文件
 */

export default {
  title: regionInfo.pageTitle,
  companyInfos: 'smxxyxx',
  groupInfos: 'zhihuizhengwuxiangmuzu',
  slogan: 'fightTogetherInYouth',
  imkey: process.env.VUE_APP_IM_KEY,
  logo: 'GS',
  key: 'xyGS',
  //配置主键,目前用于存储
  indexTitle: regionInfo.pageTitle,
  clientId: 'saber',
  // 客户端id
  clientSecret: 'saber_secret',
  // 客户端密钥
  tenantMode: true,
  // 是否开启租户模式
  lockPage: '/lock',
  tokenTime: 3000,
  //3000
  tenantId: '433489',
  //http的status默认放行不才用统一处理的,
  statusWhiteList: ['/login'],
  //配置首页不可关闭
  isFirstPage: false,
  fistPage: {
    label: '首页',
    value: '/wel/index',
    params: {},
    query: {},
    meta: {
      i18n: 'dashboard'
    },
    group: [],
    close: false
  },
  //配置菜单的属性
  menu: {
    iconDefault: 'iconfont icon-caidan',
    props: {
      //   label: 'alias',
      label: 'title',
      path: 'path',
      icon: 'icon',
      children: 'children'
    }
  },
  // 上传地址
  uploadUrl: '/api/blade-resource/oss/endpoint/put-file',
  // 流程设计器地址
  flowDesignUrl: 'http://localhost:9999'
};