import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.function.name";
import { ORGANIZATION_TYPE } from '@/config/constant';
import orgGroupIcon from '@/assets/images/icon-group.png';
import orgIcon from '@/assets/images/icon-jigou.png';
import deptIcon from '@/assets/images/icon-depart.png';
export var parseTreeToItems = function parseTreeToItems(treeList) {
  if (!treeList || !treeList.length) {
    return [];
  }

  return treeList.map(function (item) {
    return {
      id: item.id,
      departmentId: item.departmentId,
      organizationId: item.organizationId,
      label: item.name,
      type: item.type,
      iconImg: item.type === ORGANIZATION_TYPE.GROUP ? orgGroupIcon : item.type === ORGANIZATION_TYPE.ORG ? orgIcon : deptIcon,
      editable: false,
      items: parseTreeToItems(item.children)
    };
  });
};
export function handleTree(tree) {
  var handleData = function handleData(data) {
    data.forEach(function (ele) {
      ele.icon = ele.type === ORGANIZATION_TYPE.GROUP ? orgGroupIcon : ele.type === ORGANIZATION_TYPE.ORG ? orgIcon : deptIcon;
      ele.scopedSlots = {
        title: 'title',
        icon: 'icon'
      };
      ele.type === ORGANIZATION_TYPE.GROUP && (ele.disabled = true);
      ele.hasChildren && handleData(ele.children);
    });
  };

  handleData(tree);
  return tree;
}