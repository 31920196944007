import variables from '@/styles/element-variables.scss';
import defaultSettings from '@/settings'; // import {fetchDetail} from '@/api/sys/config/config';

var showSettings = defaultSettings.showSettings,
    tagsView = defaultSettings.tagsView,
    fixedHeader = defaultSettings.fixedHeader,
    sidebarLogo = defaultSettings.sidebarLogo;
var state = {
  theme: variables.theme,
  showSettings: showSettings,
  tagsView: tagsView,
  fixedHeader: fixedHeader,
  sidebarLogo: sidebarLogo,
  siteData: {}
};
var mutations = {
  CHANGE_SETTING: function CHANGE_SETTING(state, _ref) {
    var key = _ref.key,
        value = _ref.value;

    if (Object.prototype.hasOwnProperty.call(state, key)) {
      state[key] = value;
    }
  },
  SET_SITE_DATA: function SET_SITE_DATA(state, siteData) {
    state.siteData = siteData;
  }
};
var actions = {
  changeSetting: function changeSetting(_ref2, data) {
    var commit = _ref2.commit;
    commit('CHANGE_SETTING', data);
  } // 获取网站配置信息
  // getSite({commit}) {
  //   return new Promise((resolve, reject) => {
  //     fetchDetail({}).then(response => {
  //       const {data} = response;
  //       commit('SET_SITE_DATA', data);
  //       resolve(data);
  //     })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // }

};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};