import _objectSpread from "/opt/jenkins/workspace/zkzw-exam-web/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
export default {
  // eslint-disable-next-line vue/match-component-file-name
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: '云帆考试系统',
      logo: false
    };
  },
  computed: _objectSpread({}, mapGetters(['siteData']))
};