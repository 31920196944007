import { post } from '@/utils/request'; // eslint-disable-next-line no-duplicate-imports

import request from '@/utils/request';
export function pagingTree(data) {
  return post('/api/alamo-exam/exam/api/sys/depart/paging', data);
}
export function fetchTree(data) {
  return post('/exam/api/sys/depart/tree', data);
} //根据appCode获取完整的机构树

export var getFullTree = function getFullTree(isHasDept, appCode) {
  return request({
    url: '/api/blade-system/v2/dept/full_tree',
    method: 'get',
    params: {
      isHasDept: isHasDept,
      appCode: appCode
    }
  });
};
export function fetchDetail(id) {
  var data = {
    id: id
  };
  return post('/api/alamo-exam/exam/api/sys/depart/detail', data);
}
export function deleteData(ids) {
  var data = {
    ids: ids
  };
  return post('/api/alamo-exam/exam/api/sys/depart/delete', data);
}
export function saveData(data) {
  return post('/api/alamo-exam/exam/api/sys/depart/save', data);
}
export function sortData(id, sort) {
  var data = {
    id: id,
    sort: sort
  };
  return post('/api/alamo-exam/exam/api/sys/depart/sort', data);
}