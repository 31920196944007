import { getFullTree } from '@/api/sys/depart/depart';
import { handleTree } from '@/utils/orgTree';
var state = {
  fullTreeNoDept: null
};
var mutations = {
  SET_FULL_TREE_NO_DEPT: function SET_FULL_TREE_NO_DEPT(state, list) {
    state.fullTreeNoDept = list;
  }
};
var actions = {
  GetFullTree: function GetFullTree(_ref) {
    var commit = _ref.commit;
    return new Promise(function (resolve) {
      getFullTree(false, 'gtzw').then(function (res) {
        var fullTreeNoDept = handleTree(res.data);
        commit('SET_FULL_TREE_NO_DEPT', fullTreeNoDept);
        resolve(fullTreeNoDept);
      });
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};