import "core-js/modules/es6.number.constructor";
import _typeof from "/opt/jenkins/workspace/zkzw-exam-web/node_modules/@babel/runtime-corejs2/helpers/esm/typeof.js";
import "core-js/modules/es6.function.name";
import { validatenull } from '@/utils/';
import { aesEncryptStore, aesDecryptStore } from '@/utils/rsa';
import website from '@/config/website';
import Cookies from 'js-cookie';
var keyName = "".concat(website.key, "-");
/**
 * 存储localStorage
 */

export var setStore = function setStore() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var name = params.name,
      content = params.content,
      type = params.type,
      withoutEncrypt = params.withoutEncrypt;
  name = keyName + name;
  var obj = {
    dataType: _typeof(content),
    content: content,
    type: type,
    datetime: new Date().getTime()
  };
  var finall;

  if (withoutEncrypt) {
    finall = JSON.stringify(obj);
  } else {
    finall = aesEncryptStore(JSON.stringify(obj));
  }

  if (type) {
    window.sessionStorage.setItem(name, finall);
  } else {
    window.localStorage.setItem(name, finall);
  }
};
/**
 * 获取localStorage
 */

export var getStore = function getStore() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var name = params.name,
      debug = params.debug,
      withoutEncrypt = params.withoutEncrypt;
  name = keyName + name;
  var obj = {};
  var content = '';
  obj = window.sessionStorage.getItem(name) || window.localStorage.getItem(name) || Cookies.get(name) || '';

  if (!withoutEncrypt && obj) {
    obj = aesDecryptStore(obj);
  }

  if (validatenull(obj)) {
    return;
  }

  try {
    obj = JSON.parse(obj);
  } catch (_unused) {
    return obj;
  }

  if (debug) {
    return obj;
  }

  if (obj.dataType === 'string') {
    content = obj.content;
  } else if (obj.dataType === 'number') {
    content = Number(obj.content);
  } else if (obj.dataType === 'boolean') {
    content = obj.content;
  } else if (obj.dataType === 'object') {
    content = obj.content;
  }

  return content;
};
/**
 * 删除localStorage
 */

export var removeStore = function removeStore() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var name = params.name,
      type = params.type;
  name = keyName + name;

  if (type) {
    window.sessionStorage.removeItem(name);
  } else {
    console.log("remove local ".concat(name));
    window.localStorage.removeItem(name);
  }
};
/**
 * 获取全部localStorage
 */

export var getAllStore = function getAllStore() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var list = [];
  var type = params.type;

  if (type) {
    for (var i = 0; i <= window.sessionStorage.length; i++) {
      list.push({
        name: window.sessionStorage.key(i),
        content: getStore({
          name: window.sessionStorage.key(i),
          type: 'session'
        })
      });
    }
  } else {
    for (var _i = 0; _i <= window.localStorage.length; _i++) {
      list.push({
        name: window.localStorage.key(_i),
        content: getStore({
          name: window.localStorage.key(_i)
        })
      });
    }
  }

  return list;
};
/**
 * 清空全部localStorage
 */

export var clearStore = function clearStore() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var type = params.type;

  if (type) {
    window.sessionStorage.clear();
  } else {
    var versionLocal = localStorage.getItem('version');
    var issuingAuthorityList = window.localStorage.getItem('xyGS-issuingAuthorityList') || '';
    var loginInfo = window.localStorage.getItem('xyGS-loginInfo') || '';
    window.localStorage.clear();
    window.localStorage.setItem('version', versionLocal);
    window.localStorage.setItem('xyGS-issuingAuthorityList', issuingAuthorityList);
    window.localStorage.setItem('xyGS-loginInfo', loginInfo);
  }
};