//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  // eslint-disable-next-line vue/match-component-file-name
  name: 'Hamburger',
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleClick: function toggleClick() {
      this.$emit('toggleClick');
    }
  }
};