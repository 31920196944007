import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import axios from 'axios';
import { MessageBox, Message, Loading } from 'element-ui';
import store from '@/store';
import { getStore } from '@/utils/store';
import { urlSafe, uuid, serialize } from '@/utils/';
import { Base64 } from 'js-base64';
import website from '@/config/website';
import { decrypt, aesEncrypt, aesDecrypt, signature } from '@/utils/rsa'; // 请求实例

var instance = axios.create({
  timeout: 60000,
  baseURL: process.env.VUE_APP_BASE_URL
}); // 请求前置过滤器

instance.interceptors.request.use(function (config) {
  config.retry = false;
  config.headers['Authorization'] = "Basic ".concat(Base64.encode("".concat(website.clientId, ":").concat(website.clientSecret)));
  config.headers['Accept-Language'] = 'zh-CN';
  config.headers['device-id'] = getStore({
    name: 'deviceid'
  }) || uuid('deviceid');
  config.headers['device-type'] = 'browser';
  var token = getStore({
    name: 'token'
  });
  var checkeUrl = config.url.indexOf('blade-auth') >= 0;

  if (!checkeUrl && token) {
    config.headers['Blade-Auth'] = urlSafe(aesEncrypt("Bearer ".concat(token))); //  让每个请求携带token--['Authorization']为自定义key 请根据实际情况自行修改
  }

  var checkeUpload = config.url.indexOf('import') >= 0; //  headers中配置serialize为true开启序列化

  if (checkeUrl) {
    config.data && (config.data = serialize(config.data));
  } else if (checkeUpload) {
    return config;
  } else if (config.method === 'post' && config.data && !checkeUrl) {
    var data = JSON.stringify(config.data);
    var dataObj = {
      data: urlSafe(aesEncrypt(data)),
      signature: signature(data)
    };
    config.data = dataObj;
  }

  return config;
}, function (error) {
  console.log(error);
  return Promise.reject(error);
}); // 响应数据拦截并做通用处理

instance.interceptors.response.use(function (response) {
  var res = response;
  var status = res.status;
  var checkeUrl = response.config.url.indexOf('blade-auth') >= 0; // 判断是否blade-auth相关

  if (checkeUrl && status === 200) {
    if (res.config.url.indexOf('state') >= 0) {
      return Promise.resolve(res);
    }

    var dataObj = new URLSearchParams(res.config.data);

    if (dataObj.has('refresh_token')) {
      // 判断是否为刷新token
      res = JSON.parse(aesDecrypt(response.data.data));
    } else {
      response.data.data && (res = JSON.parse(decrypt(response.data.data)));
    }

    if (res.access_token || res.request.responseURL.indexOf('/captcha') >= 0) {
      return Promise.resolve(res);
    }

    var message = res.data.msg || '服务器错误'; //  如果请求为非200

    Message({
      message: message,
      type: 'error'
    });
    return Promise.reject(res);
  }

  if (status !== 200 && status !== 201) {
    if (res.data.msg && res.data.msg.includes('aesKey 不存在') && res.status === 500) {
      store.dispatch('LogOut').then(function () {
        window.location.replace('/login');
      });
    } //如果是401，则跳转到登陆页面


    if (status === 401) {
      MessageBox.confirm('系统登录超时', '系统提示', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        store.dispatch('LogOut').then(function () {
          window.location.replace('/login');
        });
      });
      return Promise.reject(res);
    } else if (status === 400 && checkeUrl) {
      return Promise.reject(res);
    }

    if (res.data) {
      res.data.msg = res.data && res.data.msg || '未知错误';
    } else {
      res.data = {
        msg: '未知错误'
      };
    }

    return Promise.reject(res);
  }

  if (res.data.code === 400) {
    return Promise.reject(res);
  }

  var checkeDownload = response.config.url.indexOf('get-file') >= 0 || response.config.url.indexOf('export') >= 0;

  if (checkeDownload) {
    return Promise.resolve(res.data);
  }

  if (res.data.data && JSON.stringify(res.data.data) !== '{}') {
    try {
      var result = aesDecrypt(response.data.data);

      try {
        res.data.data = JSON.parse(result);
      } catch (e) {
        res.data.data = result;
      }
    } catch (e) {
      return Promise.resolve(res.data);
    }
  } // 如果不存在解密数据，不用解密data


  return Promise.resolve(res.data);
}, function (error) {
  if (error.message.includes('timeout')) {
    Message({
      message: '请求超时，请稍后再试',
      type: 'error'
    });
    return Promise.reject(error);
  }

  Message({
    message: '网络连接异常，请检查网络',
    type: 'error'
  });
  return Promise.reject(new Error(error));
});
/**
 * 上传
 * @param url
 * @param data
 */

export function upload(url, file, data) {
  var formData = new FormData();
  formData.append('file', file); // 附加数据

  if (data) {
    Object.keys(data).forEach(function (key) {
      formData.append(key, data[key]);
    });
  }

  return new Promise(function (resolve, reject) {
    // 打开
    var loading = Loading.service({
      text: '正在上传数据...',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    instance.request({
      url: url,
      method: 'post',
      data: formData,
      timeout: 1200000
    }).then(function (response) {
      console.log(response);
      loading.close();
      resolve(response);
    }).catch(function (err) {
      loading.close();
      reject(err);
    });
  });
}
/**
 * 下载
 * @param url
 * @param data
 */

export function download(url, data, fileName) {
  return new Promise(function (resolve, reject) {
    // 打开
    var loading = Loading.service({
      text: '正在下载数据...',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    instance.request({
      url: url,
      method: 'post',
      data: data,
      timeout: 1200000,
      responseType: 'blob'
    }).then(function (res) {
      loading.close(); // 文件下载

      var blob = new Blob([res.data], {
        type: 'application/vnd.ms-excel'
      }); // 获得文件名称

      var link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', fileName);
      link.click();
      link = null;
      Message.success('导出成功!');
    }).catch(function (err) {
      loading.close();
      reject(err);
    });
  });
}
/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url) {
  var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return new Promise(function (resolve, reject) {
    instance.post(url, data).then(function (response) {
      resolve(response);
    }, function (err) {
      reject(err);
    });
  });
}
export default instance;