module.exports = {
  smx: {
    regionName: '三门峡',
    aliasName: '甘棠',
    pageTitle: '甘棠政务平台',
    tenantId: '433489',
    faviconIcon: 'https://gtzw-web-resources.oss-cn-north-2-gov-1.aliyuncs.com/img/20210813091947.png'
  },
  zhoukou: {
    regionName: '周口',
    aliasName: '周口',
    pageTitle: '周口政务平台',
    tenantId: '433489',
    faviconIcon: 'https://gtzw-web-resources.oss-cn-north-2-gov-1.aliyuncs.com/img/favicon.png'
  }
};