import { post } from '@/utils/request';
export function login(data) {
  return post('/api/alamo-exam/exam/api/sys/user/login', data);
}
export function getInfo(token) {
  return post("/api/alamo-exam/exam/api/sys/user/info?token=".concat(token));
}
export function logout() {
  return post('/api/alamo-exam/exam/api/sys/user/logout', {});
}
export function reg(data) {
  return post('/api/alamo-exam/exam/api/sys/user/reg', data);
}